<template>
  <page title="Test Page">
    <div class="row">
      <div class="col col-2">
        <b-btn variant="success" @click="onShowNotification">show notification</b-btn>
      </div>
      <div class="col col-2">
        <b-btn variant="success" @click="onShowModal">show modal</b-btn>
      </div>
    </div>
  </page>
</template>

<script>
import Page from "@/pages/Common/Page";
import TestDialog from "@/pages/Common/TestDialog";

export default {
  name: "TestPage",
  components: {
    Page
  },
  methods: {
    onShowNotification() {
      this.$notify({
        group: 'main',
        type: 'error',
        title: 'Testowy notify',
        text: 'Testowy komunikat'
      })
    },
    onShowModal() {
      this.$modal.show(TestDialog,
          {},
          {
            'adaptive': true,
            'height': 'auto',
            'width': '75%'
          })
    }
  }
}
</script>

<style scoped>

</style>